const StartButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <>
      <svg
        width="680"
        // height="680"
        viewBox="0 0 922 922"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="start-button-mask" fill="#fff">
          <path d="M461 0L467.273 341.304L509.188 2.52542L479.75 342.616L556.847 10.0739L492.022 345.224L603.457 22.563L503.954 349.101L648.506 39.8555L515.415 354.204L691.5 61.7623L526.28 360.477L731.969 88.0432L536.43 367.851L769.469 118.41L545.754 376.246L803.59 152.531L554.149 385.57L833.957 190.031L561.523 395.72L860.238 230.5L567.796 406.585L882.144 273.494L572.899 418.046L899.437 318.543L576.776 429.978L911.926 365.153L579.384 442.25L919.475 412.812L580.696 454.727L922 461L580.696 467.273L919.475 509.188L579.384 479.75L911.926 556.847L576.776 492.022L899.437 603.457L572.899 503.954L882.144 648.506L567.796 515.415L860.238 691.5L561.523 526.28L833.957 731.969L554.149 536.43L803.59 769.469L545.754 545.754L769.469 803.59L536.43 554.149L731.969 833.957L526.28 561.523L691.5 860.238L515.415 567.796L648.506 882.144L503.954 572.899L603.457 899.437L492.022 576.776L556.847 911.926L479.75 579.384L509.188 919.475L467.273 580.696L461 922L454.727 580.696L412.812 919.475L442.25 579.384L365.153 911.926L429.978 576.776L318.543 899.437L418.046 572.899L273.494 882.144L406.585 567.796L230.5 860.238L395.72 561.523L190.031 833.957L385.57 554.149L152.531 803.59L376.246 545.754L118.41 769.469L367.851 536.43L88.0432 731.969L360.477 526.28L61.7623 691.5L354.204 515.415L39.8555 648.506L349.101 503.954L22.563 603.457L345.224 492.022L10.0739 556.847L342.616 479.75L2.52542 509.188L341.304 467.273L0 461L341.304 454.727L2.52542 412.812L342.616 442.25L10.0739 365.153L345.224 429.978L22.563 318.543L349.101 418.046L39.8555 273.494L354.204 406.585L61.7623 230.5L360.477 395.72L88.0432 190.031L367.851 385.57L118.41 152.531L376.246 376.246L152.531 118.41L385.57 367.851L190.031 88.0432L395.72 360.477L230.5 61.7623L406.585 354.204L273.494 39.8555L418.046 349.101L318.543 22.563L429.978 345.224L365.153 10.0739L442.25 342.616L412.812 2.52542L454.727 341.304L461 0Z" />
        </mask>
        <path
          d="M461 0L467.273 341.304L509.188 2.52542L479.75 342.616L556.847 10.0739L492.022 345.224L603.457 22.563L503.954 349.101L648.506 39.8555L515.415 354.204L691.5 61.7623L526.28 360.477L731.969 88.0432L536.43 367.851L769.469 118.41L545.754 376.246L803.59 152.531L554.149 385.57L833.957 190.031L561.523 395.72L860.238 230.5L567.796 406.585L882.144 273.494L572.899 418.046L899.437 318.543L576.776 429.978L911.926 365.153L579.384 442.25L919.475 412.812L580.696 454.727L922 461L580.696 467.273L919.475 509.188L579.384 479.75L911.926 556.847L576.776 492.022L899.437 603.457L572.899 503.954L882.144 648.506L567.796 515.415L860.238 691.5L561.523 526.28L833.957 731.969L554.149 536.43L803.59 769.469L545.754 545.754L769.469 803.59L536.43 554.149L731.969 833.957L526.28 561.523L691.5 860.238L515.415 567.796L648.506 882.144L503.954 572.899L603.457 899.437L492.022 576.776L556.847 911.926L479.75 579.384L509.188 919.475L467.273 580.696L461 922L454.727 580.696L412.812 919.475L442.25 579.384L365.153 911.926L429.978 576.776L318.543 899.437L418.046 572.899L273.494 882.144L406.585 567.796L230.5 860.238L395.72 561.523L190.031 833.957L385.57 554.149L152.531 803.59L376.246 545.754L118.41 769.469L367.851 536.43L88.0432 731.969L360.477 526.28L61.7623 691.5L354.204 515.415L39.8555 648.506L349.101 503.954L22.563 603.457L345.224 492.022L10.0739 556.847L342.616 479.75L2.52542 509.188L341.304 467.273L0 461L341.304 454.727L2.52542 412.812L342.616 442.25L10.0739 365.153L345.224 429.978L22.563 318.543L349.101 418.046L39.8555 273.494L354.204 406.585L61.7623 230.5L360.477 395.72L88.0432 190.031L367.851 385.57L118.41 152.531L376.246 376.246L152.531 118.41L385.57 367.851L190.031 88.0432L395.72 360.477L230.5 61.7623L406.585 354.204L273.494 39.8555L418.046 349.101L318.543 22.563L429.978 345.224L365.153 10.0739L442.25 342.616L412.812 2.52542L454.727 341.304L461 0Z"
          stroke="#fff"
          strokeWidth="21.1145"
          mask={`url(#start-button-mask)`}
        />
      </svg>

      <button onClick={onClick} className="start-camera-button">
        click to open camera
      </button>
    </>
  );
};

export default StartButton;
